<template>
  <!--SHOPPING CART-->
  <b-modal id="side-wishlist" size="xl" centered hide-header hide-footer>
    <div class="d-flex align-items-center mb-1">
      <fawesome-pro variant="far" icon="chevron-left" class="side-wishlist__back-icon cursor-pointer" @click.native="$bvModal.hide('side-wishlist')"/>
      <div class="side-cart__title ml-lg-3 ml-2 flex-grow-1">Wish-Liz</div>
      <small class="align-self-start pr-1">{{ `Showing ${wishlist.length} item${wishlist.length == 1 ? "" : "s"}` }}</small>
    </div>
    <div class="row" v-if="wishlist.length == 0">
      <div class="col-md-12 text-center mt-5">
        <img src="@/assets/img/shoping-bag-empty.png" alt="" />
        <h4>You have no items in your wishlist</h4>
      </div>
    </div>
    <div v-else class="row no-gutters scrollable">
      <div
        v-for="(product, index) in wishlist"
        :key="index"
        class="col-6 col-lg-4"
      >
        <ProductBox
          :product="product"
          @closeSideWishlist="$bvModal.hide('side-wishlist')"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import { toaster } from "@/_helpers";
import axios from "axios";
import ProductBox from "@/components/mains/ProductBox.vue";

export default {
  components: {
    ProductBox,
  },
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    },
    apiKey() {
      return process.env.VUE_APP_API_KEY;
    },
    wishlist() {
      return this.$store.getters.getWishlist;
    },
  },
  methods: {
    removeFromWishList(product) {
      axios
        .delete(this.apiUrl + "users/products/wishlist/" + product.id, {
          headers: {
            api_key: this.apiKey,
            token: this.$store.getters.getUserToken,
            "Content-Type": "application/json",
          },
        })
        .then(() => {
          let data = this.wishlist;
          data.splice(
            data.findIndex((el) => el.id == product.id),
            1
          );
          this.$store.dispatch("setWishlist", data);
          toaster.make(product.name + " has been removed from your WishLiz")
        });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    replaceByDefaultImage(img) {
      img.target.src = require("@/assets/img/product-default.png");
    },
  },
};
</script>

